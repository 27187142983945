<template>
  <div v-if="landingPageData.id !== null">
    <div
      v-if="userData !== null"
      class="demo-spacing-0"
    >
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span v-if="$router.currentRoute.params.slug !== undefined && $router.currentRoute.params.slug.slice(0, 2) === '$2'">
            You are vieweing landing page for internal use only. <strong>You can fill form manually.</strong>
          </span>
          <span v-else>During landing page preview, all form submission <strong>will not be recorded.</strong></span>
        </div>
      </b-alert>
    </div>
    <b-overlay
      v-if="checkActiveLPDate() && this.assignedSalespersons.length > 0 && landingPageCompanySlugState"
      :show="overlay"
      variant="light"
      :opacity="0.7"
      blur="2px"
      rounded="sm"
    >
      <!-- faq search section -->
      <section id="header">
        <b-card
          overlay
          class="landing-page-header"
        >
          <b-img
            v-show="landingPageData.picture_hero_link !== undefined"
            class="header-hero-img"
            :src="landingPageData.picture_hero_link"
          />
          <div class="container w-75">
            <b-row class="wrapper-logo">
              <b-col
                cols="12"
                md="6"
                class="header-logo d-flex justify-content-start mx-auto"
                style="position: absolute; top: 0;"
              >
                <div
                  class="card-icon"
                  style="height: fit-content;"
                >
                  <b-img
                    v-show="landingPageData.picture_link !== undefined"
                    :src="landingPageData.picture_link"
                    style="width: 100%; object-fit: contain; padding: 2rem 1rem;"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="container w-75">
            <b-row class="wrapper-text mb-2">
              <b-col
                cols="12"
                md="7"
                class="header-text align-self-center d-flex justify-content-start"
                style="position: absolute; top: 35%;"
              >
                <b-card-text class="brand-text w-50">
                  <h1
                    class="text-white text-left"
                    style="font-size: 2.7rem"
                  >
                    <b>{{ landingPageData.title }}</b>
                  </h1>
                  <h3 class="text-white mb-1 mt-2 text-left">
                    {{ landingPageData.description }}
                  </h3>
                </b-card-text>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </section>
      <div
        id="form"
        class="container"
      >
        <b-row class="d-flex justify-content-start my-4 lp-body-form">
          <b-col
            md="6"
            sm="12"
            xs="12"
          >
            <div class="mt-0 py-2 w-75 description">
              <span
                id="body_description"
                ref="body_description"
                v-html="body_description"
              />
            </div>
            <div
              v-if="socialMedia"
              class="social-media"
            >
              <div class="follow-us pr-md-1 pb-1 pb-md-0">
                <h4 class="m-0">
                  Follow Us On
                </h4>
              </div>
              <div
                class="social-media-link"
                style="font-size: 20px;"
              >
                <b-link
                  v-if="landingPageData.instagram"
                  :href="landingPageData.instagram"
                  target="_blank"
                  class="p-1"
                >
                  <i class="bi bi-instagram" />
                </b-link>
                <b-link
                  v-if="landingPageData.facebook"
                  :href="landingPageData.facebook"
                  target="_blank"
                  class="p-1"
                >
                  <i class="bi bi-facebook" />
                </b-link>
                <b-link
                  v-if="landingPageData.linkedin"
                  :href="landingPageData.linkedin"
                  target="_blank"
                  class="p-1"
                >
                  <i class="bi bi-linkedin" />
                </b-link>
                <b-link
                  v-if="landingPageData.youtube"
                  :href="landingPageData.youtube"
                  target="_blank"
                  class="p-1"
                >
                  <i class="bi bi-youtube" />
                </b-link>
                <b-link
                  v-if="landingPageData.tiktok"
                  :href="landingPageData.tiktok"
                  target="_blank"
                  class="p-1"
                >
                  <i class="bi bi-tiktok" />
                </b-link>
              </div>
            </div>
          </b-col>
          <b-col
            md="6"
            sm="12"
            xs="12"
            class="d-flex justify-content-md-start justify-content-xs-center justify-content-sm-center"
          >
            <div
              class="auth-v1 form lp-form w-100"
            >
              <form-field
                :is-landing-page-show="true"
                :hidden-submit-button="false"
                :form-id="formId"
                :user-self-data="userData"
                :landing-page-uuid="landingPageData.uuid"
                :submit-button="submitButton"
                :aggreement="aggreement"
                :input-manually="$router.currentRoute.params.slug !== undefined && $router.currentRoute.params.slug.slice(0, 2) === '$2' ? true : false"
                :assigned-salespersons="$router.currentRoute.params.slug !== undefined && $router.currentRoute.params.slug.slice(0, 2) === '$2' ? assignedSalespersonOptions : []"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="external-link d-flex justify-content-center mt-3 mb-2">
          <template
            v-for="(item, index) in landingPageData.url_link"
          >
            <div
              :key="index"
              class="mt-2 mx-2 text-center"
            >
              <b-button
                block
                variant="outline-secondary"
                pill
                :href="item"
                target="_blank"
                :style="{
                  color: landingPageData.color_submit_button + ' !important',
                  backgroundColor: 'transparent !important',
                  borderColor: landingPageData.color_submit_button + ' !important',
                }"
              >
                {{ landingPageData.text_link[index] }}
              </b-button>
            </div>
          </template>
        </b-row>
      </div>
    </b-overlay>

    <div
      v-else
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">
        <div>
          <h2 class="mb-1">
            Oops! This landing page is not available at the moment. 🚀
          </h2>
          <p class="mb-3">
            Sorry about that. If  you still need help, visit our support page.
          </p>
          <b-button
            variant="gradient-primary"
            href="/"
          >
            Go to homepage
          </b-button>
        </div>

        <b-img
          fluid
          :src="require('@/assets/images/pages/coming-soon.svg')"
          alt="Coming soon page"
        />
      </div>
    </div>

    <!-- footer -->
    <Footer />
  </div>

</template>

<script>
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { required, email } from '@validations'
import FormField from '@/views/menus/forms/form-display/FormField.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCol, BRow, BCardText, BButton, BOverlay, BImg, BAlert, BCard, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Footer from '@/views/menus/Footer.vue'

export default {
  components: {
    // VuexyLogo,
    FormField,
    Footer,
    BButton,
    BCardText,
    BCol,
    BRow,
    BLink,
    BOverlay,
    BImg,
    BAlert,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disableBtn: false,
      overlay: false,
      landingPageCompanySlugState: true,
      formSucceded: false,
      formStore: {},
      // validation
      required,
      email,
      socialMedia: true,
      landingPageData: {
        id: null,
        picture_link: null,
        description: '',
        body_description: '',
        aggreement: '',
        expired_finish_date: '',
        expired_start_date: '',
        label_submit_button: '',
        color_submit_button: '',
        title: null,
        form_id: null,
        text_link: [],
        color_link: [],
        url_link: [],
        marketing_link: '',
        instagram: '',
        facebook: '',
        linkedin: '',
        youtube: '',
        tiktok: '',
      },
      body_description: '',
      aggreement: '',
      formId: null,
      landing_page_company: '',
      formData: {
        landing_page_slug: null,
        landing_page_query: this.$route.query,
      },
      submitButton: {
        label: '',
        color: '',
      },
      userData: null,
      assignedSalespersons: [],
      assignedSalespersonOptions: [],
      gtmID: 'GTM-T6ZM6BT',
    }
  },
  computed: {
  },
  created() {
    this.getSelf()
    this.getLandingPageForm()
    this.getAssignedSalesperson()
  },
  methods: {
    getLandingPageForm() {
      let payload = null
      let lpGetURL = ''
      if (this.$router.currentRoute.params.uuid !== undefined){
        payload = {
          uuid: this.$router.currentRoute.params.uuid,
        }
        lpGetURL = 'menu-landing-page/getLandingPageFormByUuid'
      } else if (this.$router.currentRoute.params.slug !== undefined){
        if (this.$router.currentRoute.params.slug.slice(0, 2) === '$2') {
          payload = {
            uncountSlug: this.$router.currentRoute.params.slug,
          }
          lpGetURL = 'menu-landing-page/getLandingPageFormByUncountSlug'
        } else {
          payload = {
            slug: this.$router.currentRoute.params.slug,
          }
          lpGetURL = 'menu-landing-page/getLandingPageFormBySlug'
        }
      }
      this.overlay = true
      store.dispatch(lpGetURL, payload)
        .then(response => {
          // resolve Landing Page data
          const strBody = String(response.data.data.body_description)
          this.body_description = strBody.replace(/rel="/g, 'rel="nofollow ')

          const strAggrement = String(response.data.data.aggreement)
          this.aggreement = strAggrement.replace(/rel="/g, 'rel="nofollow ')
          this.landingPageData = response.data.data
          this.landingPageData.text_link = JSON.parse(this.landingPageData.text_link)
          this.landingPageData.color_link = JSON.parse(this.landingPageData.color_link)
          this.landingPageData.url_link = JSON.parse(this.landingPageData.url_link)
          this.formId = this.landingPageData.form_id
          this.submitButton.label = this.landingPageData.label_submit_button
          this.submitButton.color = this.landingPageData.color_submit_button
          this.landing_page_company = this.landingPageData.creator.company.name

          // Check empty social media link
          this.socialMedia = (
            this.landingPageData.instagram
            || this.landingPageData.facebook
            || this.landingPageData.linkedin
            || this.landingPageData.youtube
            || this.landingPageData.tiktok
          )

          // change Tab/Page Title
          document.title = `${this.landing_page_company}  -  ${this.landingPageData.analytic.page_title}`

          this.formatCompanySlug(this.landing_page_company)
          // check query param channel or no query
          const uri = window.location.search.substring(1)
          const params = new URLSearchParams(uri)
          if (uri === ''){
            this.overlay = false
          } else if (params.get('channel')){ // check if query param inside marketing channels
            const marketingLinks = this.landingPageData.marketing_link
            marketingLinks.map(val => {
              if (val.param === params.get('channel')) {
                this.overlay = false
              } else {
                const updatedQuery = { ...params }
                delete updatedQuery.channel
                this.$router.push({ query: updatedQuery })
                this.overlay = false
              }
              return true
            })
          }
          this.storeVisitor()
        })
        .catch(error => {
          console.log(error)
          if (error.response !== undefined){
            this.$swal({
              icon: 'error',
              variant: 'danger',
              title: 'Error occured',
              text: 'Landing Page with that company or slug not found !',
            })
          }
        })
    },
    formatCompanySlug(companyName){
      // const textLP = 'PT. Stucel-12 & Company_User'
      if (this.$router.currentRoute.params.company !== undefined) {
        if (companyName.replace(/\W+/g, ' ').replace(/\s/g, '-').toLowerCase() === this.$router.currentRoute.params.company) {
          this.landingPageCompanySlugState = true
        } else {
          this.landingPageCompanySlugState = false
        }
      }
    },
    storeVisitor(){
      this.formData.landing_page_slug = this.$router.currentRoute.params.slug
      if (this.userData !== null){
        this.formData.user_id = this.userData.id ?? null
      }
      if (this.$router.currentRoute.params.slug !== undefined) {
        if (this.$router.currentRoute.params.slug.slice(0, 2) !== '$2'){
          store.dispatch('menu-landing-page/storeVisitor', this.formData)
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    getSelf(){
      store.dispatch('app-user/getSelf')
        .then(response => {
          this.userData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getErrorToast(message){
      return this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Invalid to access Landing Page',
          icon: 'XIcon',
          variant: 'danger',
          text: message,
        },
      })
    },
    checkActiveLPDate(){
      if (new Date(this.landingPageData.expired_start_date) <= new Date()
          && new Date(this.landingPageData.expired_finish_date) >= new Date()
      ){
        return true
      }
      // this.getErrorToast('This Landing Page is out of date')
      return false
    },
    async getAssignedSalesperson(){ // check if exist salesperson in list of current LP
      if (this.$router.currentRoute.params.uuid !== undefined){
        await store.dispatch('menu-landing-page/getAssignedSalespersonByUuid', {
          uuid: this.$router.currentRoute.params.uuid,
        })
          .then(response => {
            this.assignedSalespersons = response.data.data.origin
            this.assignedSalespersonOptions = response.data.data.options
          })
          .catch(error => {
            console.log(error)
          })
      } else if (this.$router.currentRoute.params.slug !== undefined){
        if (this.$router.currentRoute.params.slug.slice(0, 2) === '$2') {
          await store.dispatch('menu-landing-page/getAssignedSalespersonByUncountSlug', {
            uncountSlug: this.$router.currentRoute.params.slug,
          })
            .then(response => {
              this.assignedSalespersons = response.data.data.origin
              this.assignedSalespersonOptions = response.data.data.options
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          await store.dispatch('menu-landing-page/getAssignedSalespersonBySlug', {
            slug: this.$router.currentRoute.params.slug,
          })
            .then(response => {
              this.assignedSalespersons = response.data.data.origin
              this.assignedSalespersonOptions = response.data.data.options
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
  },
  // mounted() {
  //   // Create a script tag and set its text content
  //   const script = document.createElement("script");
  //   script.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','`+this.gtmID+`');`;

  //   // Append the script tag to the header
  //   document.head.appendChild(script);

  //   // Create a noscript tag
  //   const noscript = document.createElement("noscript");

  //   // Create a noscript iframe inside the noscript tag
  //   const iframe = document.createElement("iframe");
  //   iframe.setAttribute("src", "https://www.googletagmanager.com/ns.html?id="+this.gtmID);
  //   iframe.style.display = "none";
  //   iframe.style.height = "0";
  //   iframe.style.width = "0";

  //   // Append the iframe to the noscript tag
  //   noscript.appendChild(iframe);

  //   // Append the noscript tag to the body
  //   document.body.appendChild(noscript);
  // },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .landing-page-header {
    background-color: black;
    height: 600px;
    border-radius: 0;
  }
  .landing-page-header .card-img-overlay{
    padding: 0;
  }
  .header-hero-img {
    object-fit: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    padding: 0;
  }
  .lp-form {
    margin-top: -450px;
  }
  #form {
    width: 75% !important;
  }
  @media (max-width: 820px) {
    #form, #header .container {
      width: 90% !important;
    }
    #header .container .brand-text {
      width: 80% !important;
    }
  }
  @media (max-width: 767px) {
    .description {
      text-align: left;
      width: 100% !important;
      margin-top: 10rem;
    }
    .lp-body-form {
      flex-direction: column-reverse;
    }
    .lp-form {
      margin-top: -120px;
      display: flex;
      justify-content: center;
    }
    .header-text, .header-logo {
      justify-content: center !important;
      left: 0;
    }
    .header-text .card-text h1, .header-text .card-text h3 {
      text-align: center !important;
    }
    .social-media {
      display: block !important;
      text-align: center !important;
      width: 100%;
    }
    .brand-text {
      width: 75% !important
    }
  }
  .social-media {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .social-media-link a {
    color: grey;
  }
  .social-media-link a:hover {
    color: #1b4694;
  }
  #body_description ul {
    list-style: none;
    padding: 0;
  }
  #body_description ul li {
    padding-left: 1.5rem;
    padding-top: 0.8rem;
    display: flex;
  }
  #body_description ul li:first-child,
  #body_description ol li:first-child {
    padding-top: 0;
  }
  #body_description ul li:before {
    content: "\f058"; /* FontAwesome Unicode */
    font-family: 'Font Awesome 6 Free';
    font-size: 1.3rem;
    display: inline-block;
    margin-left: -1.5rem;
    margin-right: 5px;
    color: rgb(20, 206, 20);
  }
  #body_description ol {
    padding-left: 2.5rem;
  }
  #body_description ol li {
    margin-left: -1.5rem;
    padding-top: 0.8rem;
  }
  #body_description p {
    color: rgb(26, 26, 26);
    margin-bottom: 0;
  }
  #body_description a {
    color: #06c !important;
  }
</style>
